import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation/Navigation";
import '../../translation';
import { useTranslation } from 'react-i18next';

const ActivitesSportives = () => {
    const { t } = useTranslation('activitesSportives');
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Activités sportives | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <Sidebar/>
                <div className='content fadeInEarth'>
                    <div className='special-title'>{t('t1')}</div>
                    <p className='text'>{t('t2')}</p><br/>
                    <ul className='liste'>
                        <li><div className='dot' />{t('t3')}</li>
                        <li><div className='dot' />{t('t4')}</li>
                        <li><div className='dot' />{t('t5')}</li>
                    </ul>
                    <div className='page-divider' />
                    <div className='content-title mb-0'>
                        {t('t6')}
                    </div><br />
                    <ul className='liste'>
                        <li><div className='dot' />{t('t7')}</li>
                        <li><div className='dot' />{t('t8')}</li>
                        <li><div className='dot' />{t('t9')}</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default ActivitesSportives;